
import { Severity, LogEntry } from '../types/logger';

class Logger {
  private static instance: Logger;
  
  private constructor() {}
  
  static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }
    return Logger.instance;
  }

  log(severity: Severity, message: string, meta?: any) {
    const timestamp = new Date().toISOString();
    const logEntry: LogEntry = {
      timestamp,
      severity,
      message,
      meta
    };
    
    switch (severity) {
      case 'error':
        if (meta?.error instanceof Error) {
          console.error({ 
            ...logEntry, 
            stack: meta.error.stack,
            details: {
              name: meta.error.name,
              message: meta.error.message,
              toString: meta.error.toString(),
              objectInfo: JSON.stringify(meta.error, Object.getOwnPropertyNames(meta.error))
            }
          });
        } else {
          console.error({
            ...logEntry,
            details: {
              errorType: typeof meta?.error,
              errorKeys: meta?.error ? Object.keys(meta.error) : [],
              fullError: JSON.stringify(meta?.error)
            }
          });
        }
        break;
      case 'warn':
        console.warn(logEntry);
        break;
      case 'debug':
        console.debug(logEntry);
        break;
      default:
        console.log(logEntry);
    }
  }
}

export const logger = Logger.getInstance();
