import { SOLUTION_SECTIONS, DESKTOP_SECTIONS, TOTAL_SOLUTION_SECTIONS, TOTAL_DESKTOP_SECTIONS } from '../constants/sections';
import { UserProgress } from '../types/nocodb';

interface SectionInfo {
  solution: string;
  section: string;
}

export const getSectionInfo = (sectionId: string): SectionInfo | null => {
  for (const [solution, data] of Object.entries(SOLUTION_SECTIONS)) {
    if (sectionId in data.sections) {
      return {
        solution,
        section: data.sections[sectionId]
      };
    }
  }
  return null;
};

export const getDesktopSectionInfo = (sectionId: string): SectionInfo | null => {
  for (const [category, data] of Object.entries(DESKTOP_SECTIONS)) {
    if (sectionId in data.sections) {
      return {
        solution: category,
        section: data.sections[sectionId]
      };
    }
  }
  return null;
};

export const analyzeSectionCompletion = (user: UserProgress): UserProgress => {
  const completedSections = user.solutions_checks ? 
    user.solutions_checks.split(',')
      .filter(Boolean)
      .map(section => section.trim())
      .filter(section => {
        // Only include sections that exist in our SOLUTION_SECTIONS mapping
        // and exclude sections starting with '0.' or '9.'
        return !section.startsWith('0.') && 
               !section.startsWith('9.') &&
               Object.values(SOLUTION_SECTIONS).some(solution =>
                 Object.keys(solution.sections).includes(section)
               );
      }) : [];

  // Normalize section order to match expected sequence
  const validCompletedSections = [...new Set(completedSections)].sort((a, b) => {
    const aNum = parseFloat(a);
    const bNum = parseFloat(b);
    return aNum - bNum;
  });

  const allSectionIds = getAllSectionIds(SOLUTION_SECTIONS);
  const missingSections = allSectionIds.filter(id => !validCompletedSections.includes(id));
  
  // Calculate progress
  const progress = calculateCompletionPercentage(validCompletedSections.length, allSectionIds.length);

  return {
    ...user,
    completedSections: validCompletedSections,
    missingSections,
    solutions_progress: progress
  };
};

export const analyzeDesktopCompletion = (user: UserProgress): UserProgress => {
  const completedSections = user.desktop_checks ? 
    user.desktop_checks.split(',')
      .filter(Boolean)
      .map(section => section.trim()) : [];

  const allSectionIds = getAllSectionIds(DESKTOP_SECTIONS);
  
  // Filter valid completed sections that exist in DESKTOP_SECTIONS
  const validCompletedSections = completedSections.filter(section =>
    Object.values(DESKTOP_SECTIONS).some(category =>
      Object.keys(category.sections).includes(section)
    )
  );

  const missingSections = allSectionIds.filter(id => !validCompletedSections.includes(id));
  
  // Calculate progress
  const progress = calculateCompletionPercentage(validCompletedSections.length, allSectionIds.length);

  return {
    ...user,
    completedDesktopSections: validCompletedSections,
    missingDesktopSections: missingSections,
    desktop_progress: progress
  };
};

const getAllSectionIds = (sections: typeof SOLUTION_SECTIONS | typeof DESKTOP_SECTIONS): string[] => {
  const ids: string[] = [];
  Object.values(sections).forEach(data => {
    const validSections = Object.keys(data.sections).filter(id => 
      !id.startsWith('0.') && !id.startsWith('9.')
    );
    validSections.forEach(id => {
      if (!ids.includes(id)) {
        ids.push(id);
      }
    });
  });
  return ids.sort((a, b) => {
    const aNum = parseFloat(a);
    const bNum = parseFloat(b);
    return aNum - bNum;
  });
};

const calculateProgress = (completed: string[], total: string[]): number => {
  // Filter out any '0.' sections from completed sections
  const validCompleted = completed.filter(id => !id.startsWith('0.'));
  
  if (validCompleted.length === 0) return 0;
  if (validCompleted.length >= total.length) return 100;
  
  // Check if all sections are present in the expected sequence
  const hasAllSections = total.every(id => 
    validCompleted.includes(id) || parseFloat(id) > Math.max(...validCompleted.map(c => parseFloat(c)))
  );
  
  return hasAllSections ? 
    Math.round((validCompleted.length / total.length) * 100) : 
    Math.min(Math.round((validCompleted.length / total.length) * 100), 99);
};

const calculateCompletionPercentage = (completed: number, total: number): number => {
  if (total === 0) return 0;
  return Math.min(100, Math.round((completed / total) * 100));
};