import React, { useEffect } from 'react'
import { useAuth, useLoginWithRedirect } from '@frontegg/react'
import { useNavigate } from 'react-router-dom'
import { Lock } from 'lucide-react'

export function Login() {
  const { isAuthenticated } = useAuth()
  const loginWithRedirect = useLoginWithRedirect()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard')
    }
  }, [isAuthenticated, navigate])

  const handleLogin = () => {
    loginWithRedirect()
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 flex items-center justify-center p-4">
      <div className="w-full max-w-md bg-white/5 backdrop-blur-lg rounded-2xl p-8 shadow-2xl">
        <div className="flex flex-col items-center space-y-6">
          <div className="w-[244px] h-[53px] flex items-center justify-center">
            <Lock className="w-12 h-12 text-blue-400" />
          </div>
          
          <h2 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-emerald-400">
            Research Admin Portal
          </h2>
          
          <p className="text-gray-300 text-center max-w-sm">
            Access research administration tools and insights.
          </p>
          
          <button
            onClick={handleLogin}
            className="group relative w-full flex items-center justify-center px-6 py-3 bg-gradient-to-r from-blue-500 to-emerald-500 text-white text-sm font-semibold rounded-lg overflow-hidden transition-all duration-300 hover:from-blue-600 hover:to-emerald-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-900"
          >
            <div className="absolute inset-0 w-3 bg-gradient-to-r from-white/0 via-white/20 to-white/0 skew-x-[-45deg] group-hover:animate-shimmer" />
            <Lock className="w-4 h-4 mr-2" />
            Single Sign-On
          </button>
        </div>
      </div>
    </div>
  )
}