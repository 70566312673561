import React from 'react'
import { createRoot } from 'react-dom/client'
import { logger } from './services/logger'

// Log application startup
logger.log('info', 'Application starting...', {
  environment: import.meta.env.MODE,
  version: import.meta.env.VITE_APP_VERSION || 'unknown'
});

// Global error handlers
window.onerror = (message, source, lineno, colno, error) => {
  logger.log('error', 'Global error:', { message, source, lineno, colno, error });
};

window.onunhandledrejection = (event) => {
  logger.log('error', 'Unhandled promise rejection:', event.reason);
};
import { BrowserRouter } from 'react-router-dom'
import { FronteggProvider } from '@frontegg/react'
import { UserProvider } from './contexts/UserContext'
import { App } from './App'
import { ErrorBoundary } from './components/ErrorBoundary'
import './index.css'

const contextOptions = {
  baseUrl: 'https://app-6yutcyqk6ant.us.frontegg.com',
  clientId: 'af20fef1-c350-4b74-ac85-af1538eb4c38',
  appId: '9eb8e1d7-4d78-4850-8622-6d1b68341444'
};

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <FronteggProvider contextOptions={contextOptions} hostedLoginBox={true}>
          <UserProvider>
            <App />
          </UserProvider>
        </FronteggProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
)