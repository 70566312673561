import React from 'react';
import { X, Star, MessageSquare, Monitor, Lightbulb } from 'lucide-react';
import { FeedbackSummary } from '../../types/feedback';

interface FeedbackModalProps {
  feedback: FeedbackSummary;
  onClose: () => void;
}

export const FeedbackModal: React.FC<FeedbackModalProps> = ({ feedback, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl max-w-4xl w-full max-h-[80vh] overflow-hidden">
        <div className="p-6 border-b border-gray-200 flex items-center justify-between">
          <div>
            <h2 className="text-lg font-semibold text-gray-900">Feedback Details</h2>
            <p className="text-sm text-gray-500">{feedback.email}</p>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
          >
            <X className="w-5 h-5 text-gray-500" />
          </button>
        </div>
        
        <div className="p-6 overflow-y-auto max-h-[calc(80vh-129px)]">
          {/* General Feedback */}
          {feedback.generalFeedback.length > 0 && (
            <div className="mb-8">
              <h3 className="font-medium text-gray-900 mb-4 flex items-center">
                <MessageSquare className="w-5 h-5 text-blue-500 mr-2" />
                General Feedback
              </h3>
              <div className="space-y-4">
                {feedback.generalFeedback.map((item, index) => (
                  <div key={index} className="bg-gray-50 rounded-lg p-4">
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex items-center space-x-2">
                        <Star className="w-4 h-4 text-yellow-400" />
                        <span className="font-medium">{item.Rating}/10</span>
                      </div>
                      <span className="text-sm text-gray-500">
                        {new Date(item.CreatedAt).toLocaleDateString()}
                      </span>
                    </div>
                    {item.Ideas && (
                      <div className="mb-2">
                        <span className="text-sm font-medium">Ideas:</span>
                        <p className="text-sm text-gray-600">{item.Ideas}</p>
                      </div>
                    )}
                    {item.Vendors && (
                      <div className="mb-2">
                        <span className="text-sm font-medium">Competitors:</span>
                        <p className="text-sm text-gray-600">{item.Vendors}</p>
                      </div>
                    )}
                    <div className="text-sm text-gray-600">
                      Meeting requested: {item.Meeting}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Solution Feedback */}
          {feedback.solutionFeedback.length > 0 && (
            <div className="mb-8">
              <h3 className="font-medium text-gray-900 mb-4 flex items-center">
                <Lightbulb className="w-5 h-5 text-yellow-500 mr-2" />
                Solution Feedback
              </h3>
              <div className="space-y-4">
                {feedback.solutionFeedback.map((item, index) => (
                  <div key={index} className="bg-gray-50 rounded-lg p-4">
                    <div className="flex items-center justify-between mb-2">
                      <div className="text-sm font-medium">{item.Solution}</div>
                      <div className="flex items-center space-x-2">
                        <Star className="w-4 h-4 text-yellow-400" />
                        <span className="font-medium">{item.Rating}/10</span>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 mt-2">
                      <div>
                        <span className="text-sm font-medium">Challenges:</span>
                        <p className="text-sm text-gray-600">{item.Challenges}</p>
                      </div>
                      <div>
                        <span className="text-sm font-medium">Willing to Pay:</span>
                        <p className="text-sm text-gray-600">{item.Paying}</p>
                      </div>
                    </div>
                    {item.Describe && (
                      <div className="mt-2">
                        <span className="text-sm font-medium">Description:</span>
                        <p className="text-sm text-gray-600">{item.Describe}</p>
                      </div>
                    )}
                    {item.Thoughts && (
                      <div className="mt-2">
                        <span className="text-sm font-medium">Additional Thoughts:</span>
                        <p className="text-sm text-gray-600">{item.Thoughts}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Desktop Feedback */}
          {feedback.desktopFeedback.length > 0 && (
            <div>
              <h3 className="font-medium text-gray-900 mb-4 flex items-center">
                <Monitor className="w-5 h-5 text-purple-500 mr-2" />
                Desktop Feedback
              </h3>
              <div className="space-y-4">
                {feedback.desktopFeedback.map((item, index) => (
                  <div key={index} className="bg-gray-50 rounded-lg p-4">
                    <div className="grid grid-cols-2 gap-4 mb-4">
                      {['Applications', 'Knowledge', 'Automation', 'Status Page', 'Migrations', 'Ticketing', 'Bots'].map(
                        feature => item[feature as keyof typeof item] && (
                          <div key={feature} className="flex items-center justify-between">
                            <span className="text-sm">{feature}:</span>
                            <div className="flex items-center space-x-1">
                              <Star className="w-4 h-4 text-yellow-400" />
                              <span className="text-sm font-medium">
                                {item[feature as keyof typeof item]}/10
                              </span>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                    <div className="space-y-2">
                      <div>
                        <span className="text-sm font-medium">Challenges:</span>
                        <p className="text-sm text-gray-600">{item.Challenges}</p>
                      </div>
                      <div>
                        <span className="text-sm font-medium">Importance:</span>
                        <p className="text-sm text-gray-600">{item.Importance}</p>
                      </div>
                      <div>
                        <span className="text-sm font-medium">Payment:</span>
                        <p className="text-sm text-gray-600">{item.Payment}</p>
                      </div>
                      {item.Additional && (
                        <div>
                          <span className="text-sm font-medium">Additional Feedback:</span>
                          <p className="text-sm text-gray-600">{item.Additional}</p>
                        </div>
                      )}
                      <div className="text-sm text-gray-600">
                        Call requested: {item.Call}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}