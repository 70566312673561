import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '@frontegg/react';

export function OAuthCallback() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const nonce = params.get('nonce');

    if (code && nonce) {
      navigate('/dashboard', { replace: true });
    } else {
      console.error('OAuth callback failed: Missing code or nonce');
      navigate('/login');
    }
  }, [location, navigate, user]);

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
    </div>
  );
}