import React from 'react';
import { X, CheckCircle } from 'lucide-react';

export interface FollowUpRequest {
  id?: number;
  name: string;
  email: string;
  company: string;
  date: string;
  solution?: string;
  followedUp?: boolean;
}

interface FollowUpModalProps {
  isOpen: boolean;
  onClose: () => void;
  requests: FollowUpRequest[];
  title: string;
  onFollowUpChange?: (id: number, followed: boolean) => Promise<void>;
  completedCount?: number;
}

export function FollowUpModal({ 
  isOpen, 
  onClose, 
  requests, 
  title, 
  onFollowUpChange,
  completedCount = 0
}: FollowUpModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl max-w-2xl w-full max-h-[80vh] overflow-hidden">
        <div className="p-6 border-b border-gray-200 flex items-center justify-between">
          <div>
            <h2 className="text-lg font-semibold text-gray-900">{title}</h2>
            <p className="text-sm text-gray-500">
              {requests.length} follow-up requests • {completedCount} completed
            </p>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
          >
            <X className="w-5 h-5 text-gray-500" />
          </button>
        </div>
        
        <div className="p-6 overflow-y-auto max-h-[calc(80vh-129px)]">
          <div className="space-y-4">
            {requests.map((request, index) => (
              <div 
                key={`${request.id || request.email}-${request.date}-${index}`}
                className="bg-gray-50 rounded-lg p-4"
              >
                <div className="flex items-center justify-between mb-2">
                  <div className="font-medium text-gray-900">{request.company}</div>
                  <div className="flex items-center space-x-4">
                    <div className="text-sm text-gray-500">{request.date}</div>
                    {request.id && (
                      <label className="flex items-center space-x-2 cursor-pointer">
                        <input
                          type="checkbox"
                          checked={request.followedUp}
                          onChange={(e) => onFollowUpChange?.(request.id!, e.target.checked)}
                          className="rounded border-gray-300 text-blue-600 focus:ring-blue-500 h-4 w-4"
                        />
                        <span className="text-sm text-gray-600">Completed</span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="text-sm text-gray-600">
                  <span className="font-medium">Contact:</span> {request.name}
                </div>
                {request.email && (
                  <div className="text-sm text-gray-600">
                    <span className="font-medium">Email:</span> {request.email}
                  </div>
                )}
                {request.solution && (
                  <div className="text-sm text-gray-600 mt-1">
                    <span className="font-medium">Solution:</span> {request.solution}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}