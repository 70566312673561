
import axios, { AxiosError } from 'axios';
import { FinancialInterest } from '../types/nocodb';
import { logger } from './logger';

const API_URL = import.meta.env.VITE_REACT_APP_NOCODB_API_URL;
const API_TOKEN = import.meta.env.VITE_REACT_APP_NOCODB_API_TOKEN;

export const TABLE_IDS = {
  USERS: 'm8sj23ouy6jfsnr',
  GENERAL_FEEDBACK: 'm4ep2wkxo1xoxs1',
  SOLUTION_FEEDBACK: 'mlthj26htea1pcd',
  SOLUTIONS: 'mj5e0isyx8poscz',
  INTEGRATION_FEEDBACK: 'mrb9y2e0h0etg7p',
  DESKTOP_FEEDBACK: 'mxvyxhmo76xx69k',
  FINANCIAL: 'meqbgspu70emn7t'
};

const nocodbApi = axios.create({
  baseURL: API_URL,
  headers: {
    'xc-token': API_TOKEN
  },
  timeout: 10000
});

logger.log('info', 'NocoDB API initialized', {
  baseURL: API_URL,
  hasToken: !!API_TOKEN
});

nocodbApi.interceptors.response.use(
  response => response,
  async (error: AxiosError) => {
    logger.log('error', 'API Error:', { 
      url: error.config?.url,
      method: error.config?.method,
      status: error.response?.status,
      data: error.response?.data
    });
    return Promise.reject(error);
  }
);

export const getGeneralFeedback = async () => {
  const response = await nocodbApi.get(`/api/v2/tables/${TABLE_IDS.GENERAL_FEEDBACK}/records`);
  return response.data.list;
};

export const getUserProgress = async () => {
  const response = await nocodbApi.get(`/api/v2/tables/${TABLE_IDS.USERS}/records`);
  return response.data.list;
};

export const getDesktopFeedback = async () => {
  const response = await nocodbApi.get(`/api/v2/tables/${TABLE_IDS.DESKTOP_FEEDBACK}/records`);
  return response.data.list;
};

export const getIntegrationFeedback = async () => {
  const response = await nocodbApi.get(`/api/v2/tables/${TABLE_IDS.INTEGRATION_FEEDBACK}/records`);
  return response.data.list;
};

export const getFinancialInterests = async () => {
  const response = await nocodbApi.get(`/api/v2/tables/${TABLE_IDS.FINANCIAL}/records`);
  return response.data.list as FinancialInterest[];
};

export const getSolutionFeedback = async () => {
  const response = await nocodbApi.get(`/api/v2/tables/${TABLE_IDS.SOLUTION_FEEDBACK}/records`);
  return response.data.list;
};

export const updateDesktopFollowUp = async (data: any) => {
  if (data.Id) {
    const response = await nocodbApi.patch(`/api/v2/tables/${TABLE_IDS.DESKTOP_FEEDBACK}/records/${data.Id}`, data);
    return response.data;
  } else {
    const response = await nocodbApi.post(`/api/v2/tables/${TABLE_IDS.DESKTOP_FEEDBACK}/records`, data);
    return response.data;
  }
};

export const updateFinancialInterests = (data: FinancialInterest) => async () => {
  if (data.Id) {
    const response = await nocodbApi.patch(`/api/v2/tables/${TABLE_IDS.FINANCIAL}/records/${data.Id}`, data);
    return response.data;
  } else {
    const response = await nocodbApi.post(`/api/v2/tables/${TABLE_IDS.FINANCIAL}/records`, data);
    return response.data;
  }
};
