import React from 'react';
import { GeneralFeedbackWidget } from './GeneralFeedbackWidget';
import { TopUsers } from './TopUsers';
import { TopCompanies } from './TopCompanies';
import { FeedbackSummary } from './FeedbackSummary';
import { IntegrationOverview } from './IntegrationOverview';

export default function Dashboard() {
  return (
    <main className="flex-1 p-8">
      <div className="max-w-7xl mx-auto">
        <header className="mb-8">
          <h1 className="text-2xl font-bold text-gray-900">Research Oversight Dashboard</h1>
          <p className="text-gray-500">Monitor progress, engagement, and feedback across all platforms</p>
        </header>

        <div className="mb-8">
          <GeneralFeedbackWidget />
        </div>

        <div className="mb-8">
          <FeedbackSummary />
        </div>

        <div className="grid grid-cols-4 gap-6 mb-8">
          <IntegrationOverview />
        </div>

        <div className="grid grid-cols-2 gap-6">
          <TopCompanies />
          <TopUsers />
        </div>
      </div>
    </main>
  );
}