import React from 'react';
import { TrendingUp, Users, DollarSign, Percent } from 'lucide-react';
import { CompanyInterests } from '../../types/nocodb';
import { PRICING } from '../../config/pricing';

interface RevenueSummaryProps {
  companies: Array<{ name: string; interests: CompanyInterests }>;
}

export function RevenueSummary({ companies }: RevenueSummaryProps) {
  const calculateMetrics = () => {
    let totalSolutionsARR = 0;
    let totalDesktopMRR = 0;
    let interestedCompanies = 0;
    let totalLicenses = 0;

    companies.forEach(({ interests }) => {
      const hasInterest = 
        (interests.solutions && interests.solutions.length > 0) || 
        (interests.desktop && interests.desktop.length > 0);
      
      if (hasInterest) {
        interestedCompanies++;
      }

      // Calculate Solutions Revenue
      if (interests.solutions) {
        interests.solutions.split(',').forEach(solution => {
          const [name, count] = solution.split(':');
          const licenses = parseInt(count || '0', 10);
          const pricing = PRICING.solutions[name as keyof typeof PRICING.solutions];
          
          if (pricing) {
            if (pricing.type === 'annual') {
              totalSolutionsARR += pricing.price;
            } else {
              totalSolutionsARR += pricing.price * licenses * 12;
              totalLicenses += licenses;
            }
          }
        });
      }

      // Calculate Desktop Revenue
      if (interests.desktop) {
        interests.desktop.split(',').forEach(feature => {
          const [name, count] = feature.split(':');
          const licenses = parseInt(count || '0', 10);
          const pricing = PRICING.desktop[name as keyof typeof PRICING.desktop];
          
          if (pricing) {
            totalDesktopMRR += pricing.price * licenses;
            totalLicenses += licenses;
          }
        });
      }
    });

    const totalARR = totalSolutionsARR + (totalDesktopMRR * 12);
    const conversionRate = companies.length > 0 
      ? Math.round((interestedCompanies / companies.length) * 100) 
      : 0;

    return {
      totalARR,
      interestedCompanies,
      totalLicenses,
      conversionRate
    };
  };

  const metrics = calculateMetrics();
  const formatCurrency = (amount: number) => 
    new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);

  return (
    <div className="grid grid-cols-4 gap-6 mb-6">
      <div className="bg-white rounded-xl p-6 shadow-sm">
        <div className="flex items-center justify-between mb-4">
          <div className="bg-blue-50 p-3 rounded-lg">
            <TrendingUp className="w-6 h-6 text-blue-500" />
          </div>
          <span className="text-xs font-medium text-gray-500">Total ARR</span>
        </div>
        <div className="mt-2">
          <div className="text-2xl font-semibold">{formatCurrency(metrics.totalARR)}</div>
          <div className="text-sm text-gray-500">Annual Revenue</div>
        </div>
      </div>

      <div className="bg-white rounded-xl p-6 shadow-sm">
        <div className="flex items-center justify-between mb-4">
          <div className="bg-green-50 p-3 rounded-lg">
            <Users className="w-6 h-6 text-green-500" />
          </div>
          <span className="text-xs font-medium text-gray-500">Companies</span>
        </div>
        <div className="mt-2">
          <div className="text-2xl font-semibold">{metrics.interestedCompanies}</div>
          <div className="text-sm text-gray-500">Showing Interest</div>
        </div>
      </div>

      <div className="bg-white rounded-xl p-6 shadow-sm">
        <div className="flex items-center justify-between mb-4">
          <div className="bg-purple-50 p-3 rounded-lg">
            <DollarSign className="w-6 h-6 text-purple-500" />
          </div>
          <span className="text-xs font-medium text-gray-500">Total Licenses</span>
        </div>
        <div className="mt-2">
          <div className="text-2xl font-semibold">{metrics.totalLicenses.toLocaleString()}</div>
          <div className="text-sm text-gray-500">Active Seats</div>
        </div>
      </div>

      <div className="bg-white rounded-xl p-6 shadow-sm">
        <div className="flex items-center justify-between mb-4">
          <div className="bg-orange-50 p-3 rounded-lg">
            <Percent className="w-6 h-6 text-orange-500" />
          </div>
          <span className="text-xs font-medium text-gray-500">Conversion</span>
        </div>
        <div className="mt-2">
          <div className="text-2xl font-semibold">{metrics.conversionRate}%</div>
          <div className="text-sm text-gray-500">Of Total Companies</div>
        </div>
      </div>
    </div>
  );
}