import React, { useEffect, useState } from 'react';
import { Star, CheckCircle, DollarSign, MessageCircle, Users } from 'lucide-react';
import { SolutionFeedback, SolutionMetrics, DesktopMetrics } from '../types/nocodb';
import { getSolutionFeedback, getDesktopFeedback, getUserProgress, updateDesktopFollowUp, getGeneralFeedback } from '../services/nocodbApi';
import { FollowUpModal, FollowUpRequest } from './FeedbackPage/FollowUpModal';

export function FeedbackSummary() {
  const [metrics, setMetrics] = useState<SolutionMetrics[]>([]);
  const [desktopMetrics, setDesktopMetrics] = useState<DesktopMetrics[]>([]);
  const [loading, setLoading] = useState(true);
  const [showSolutionsModal, setShowSolutionsModal] = useState(false);
  const [showDesktopModal, setShowDesktopModal] = useState(false);
  const [solutionFollowUps, setSolutionFollowUps] = useState<FollowUpRequest[]>([]);
  const [desktopFollowUps, setDesktopFollowUps] = useState<FollowUpRequest[]>([]);
  const [completedFollowUps, setCompletedFollowUps] = useState(0);

  const handleFollowUpChange = async (id: number, followed: boolean) => {
    try {
      await updateDesktopFollowUp(id, followed);
      await fetchData();
    } catch (error) {
      console.error('Error updating follow-up status:', error);
    }
  };

  const calculateSolutionMetrics = (feedback: SolutionFeedback[]): SolutionMetrics[] => {
    const solutionMap = new Map<string, SolutionFeedback[]>();
    
    const ALL_SOLUTIONS = [
      'Automation-as-a-Service',
      'Expert Path',
      'Master Fix Database',
      'Onboarding Navigator',
      'Outage Hub',
      'Project Path',
      'Robotic Process Automation',
      'Workflow Orchestrator'
    ];

    ALL_SOLUTIONS.forEach(solution => {
      solutionMap.set(solution, []);
    });

    feedback.forEach(item => {
      const solution = item.Solution;
      if (solutionMap.has(solution)) {
        solutionMap.get(solution)?.push(item);
      }
    });

    return Array.from(solutionMap.entries()).map(([name, items]) => ({
      name,
      averageRating: items.length > 0 ? items.reduce((acc, curr) => acc + curr.Rating, 0) / items.length : 0,
      totalResponses: items.length,
      challengesAddressed: items.filter(item => item.Challenges === 'Yes').length,
      willingToPay: items.filter(item => item.Paying === 'Yes').length,
      followUpRequests: items.filter(item => item.Thoughts && item.Thoughts.trim() !== '').length
    }));
  };

  const calculateDesktopMetrics = (feedback: any[]): DesktopMetrics[] => {
    const DESKTOP_INTEGRATIONS = [
      { name: 'Applications', field: 'Applications', id: 'app' },
      { name: 'Knowledge', field: 'Knowledge', id: 'know' },
      { name: 'Automation', field: 'Automation', id: 'auto' },
      { name: 'Status Page', field: 'Status Page', id: 'status' },
      { name: 'Migrations', field: 'Migrations', id: 'mig' },
      { name: 'Ticketing', field: 'Ticketing', id: 'ticket' },
      { name: 'Your Bot', field: 'Bots', id: 'bot' }
    ];

    const totalResponses = feedback.length;
    const challengesAddressed = feedback.filter(item => item.Challenges === 'Yes').length;
    const willingToPay = feedback.filter(item => item.Payment === 'Yes').length;
    const followUpRequests = feedback.filter(item => item.Call === 'Yes').length;

    return DESKTOP_INTEGRATIONS.map(integration => {
      const relevantFeedback = feedback.filter(item => 
        item[integration.field] !== null && 
        item[integration.field] !== undefined
      );
      
      const result = {
        id: integration.id,
        name: String(integration.name || ''),
        averageRating: relevantFeedback.length > 0 
          ? relevantFeedback.reduce((acc, curr) => acc + (Number(curr[integration.field]) || 0), 0) / relevantFeedback.length 
          : 0,
        totalResponses: Number(totalResponses) || 0,
        challengesAddressed: Number(challengesAddressed) || 0,
        willingToPay: Number(willingToPay) || 0,
        followUpRequests: Number(followUpRequests) || 0
      };
      return result;
    }).sort((a, b) => (b.averageRating || 0) - (a.averageRating || 0));
  };

  const fetchData = async () => {
    try {
      const [generalData, solutionData, desktopData, userData] = await Promise.all([
        getGeneralFeedback(),
        getSolutionFeedback(),
        getDesktopFeedback(),
        getUserProgress()
      ]);

      const userMap = userData.reduce((acc: { [key: string]: any }, user) => {
        acc[user.frontegg_email] = user;
        return acc;
      }, {});

      const solutionRequests = solutionData
        .filter(item => item.Thoughts && item.Thoughts.trim() !== '')
        .map(item => ({
          id: item.Id,
          name: userMap[item.Client]?.frontegg_clientname || item.Client,
          email: item.Client,
          company: userMap[item.Client]?.frontegg_accountname || 'Unknown Company',
          date: new Date(item.CreatedAt).toLocaleString(),
          solution: item.Solution,
          followedUp: false
        }));

      const desktopRequests = desktopData
        .filter(item => item.Call === 'Yes')
        .map(item => ({
          id: item.Id,
          name: item.Email || item.Client,
          email: item.Email || item.Client,
          company: item.Client,
          date: new Date(item.CreatedAt).toLocaleString(),
          followedUp: item.FollowUp === 'Y'
        }));

      const completedCount = desktopRequests.filter(r => r.followedUp).length;

      setSolutionFollowUps(solutionRequests);
      setDesktopFollowUps(desktopRequests);
      setCompletedFollowUps(completedCount);
      
      const solutionMetrics = calculateSolutionMetrics(solutionData);
      const calculatedDesktopMetrics = calculateDesktopMetrics(desktopData);
      
      setMetrics(solutionMetrics);
      setDesktopMetrics(calculatedDesktopMetrics);
    } catch (error) {
      console.error('Error fetching feedback:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="bg-white rounded-xl p-6 shadow-sm animate-pulse">
        <div className="h-32 bg-gray-200 rounded-lg"></div>
      </div>
    );
  }

  const sortedMetrics = metrics.sort((a, b) => b.averageRating - a.averageRating);
  const totalSolutionFollowUps = metrics.reduce((acc, curr) => acc + curr.followUpRequests, 0);
  const totalDesktopResponses = desktopMetrics.length > 0 ? desktopMetrics[0].totalResponses : 0;
  const totalDesktopChallenges = desktopMetrics.length > 0 ? desktopMetrics[0].challengesAddressed : 0;
  const totalDesktopPayment = desktopMetrics.length > 0 ? desktopMetrics[0].willingToPay : 0;
  const totalDesktopFollowUps = desktopMetrics.length > 0 ? desktopMetrics[0].followUpRequests : 0;

  return (
    <div className="space-y-8">
      <div className="bg-white rounded-xl p-6 shadow-sm">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-lg font-semibold">Solutions Feedback</h2>
          <div className="flex items-center space-x-6">
            <div className="text-sm text-gray-500">
              Total Responses: {metrics.reduce((acc, curr) => acc + curr.totalResponses, 0)}
            </div>
            <button
              onClick={() => setShowSolutionsModal(true)}
              className="flex items-center space-x-2 bg-blue-50 px-4 py-2 rounded-lg hover:bg-blue-100 transition-colors"
            >
              <Users className="w-4 h-4 text-blue-500" />
              <span className="text-sm font-medium text-blue-700">
                {totalSolutionFollowUps} Follow-ups
              </span>
            </button>
          </div>
        </div>
        
        <div className="grid grid-cols-8 gap-3">
          {sortedMetrics.map((solution) => (
            <div 
              key={solution.name} 
              className={`${
                solution.totalResponses === 0 ? 'bg-gray-50' : 'bg-blue-50'
              } rounded-lg p-2.5`}
            >
              <div className="flex flex-col items-center mb-2">
                <div className="h-12 flex items-center text-center">
                  <h3 className="font-medium text-gray-900 text-sm leading-tight">
                    {solution.name}
                  </h3>
                </div>
                <div className={`flex items-center px-2 py-1 rounded-full ${
                  solution.totalResponses === 0 ? 'bg-gray-100' : 'bg-white'
                }`}>
                  <Star className={`w-4 h-4 mr-1 ${
                    solution.totalResponses === 0 ? 'text-gray-400' : 'text-yellow-400 fill-current'
                  }`} />
                  <span className="text-sm font-semibold">
                    {solution.averageRating.toFixed(1)}
                  </span>
                </div>
              </div>

              <div className="space-y-1">
                <div className="flex items-center justify-between text-xs">
                  <div className="flex items-center">
                    <CheckCircle className={`w-3 h-3 mr-1 ${
                      solution.totalResponses === 0 ? 'text-gray-400' : 'text-green-600'
                    }`} />
                    <span>{solution.totalResponses === 0 ? '0' : 
                      Math.round((solution.challengesAddressed / solution.totalResponses) * 100)}%</span>
                  </div>
                  <span className="text-gray-500 ml-1">Pain</span>
                </div>

                <div className="flex items-center justify-between text-xs">
                  <div className="flex items-center">
                    <DollarSign className={`w-3 h-3 mr-1 ${
                      solution.totalResponses === 0 ? 'text-gray-400' : 'text-blue-600'
                    }`} />
                    <span>{solution.totalResponses === 0 ? '0' : 
                      Math.round((solution.willingToPay / solution.totalResponses) * 100)}%</span>
                  </div>
                  <span className="text-gray-500 ml-1">Pay</span>
                </div>

                <div className="flex items-center justify-between text-xs">
                  <div className="flex items-center">
                    <MessageCircle className={`w-3 h-3 mr-1 ${
                      solution.totalResponses === 0 ? 'text-gray-400' : 'text-purple-600'
                    }`} />
                    <span>{solution.followUpRequests}</span>
                  </div>
                  <span className="text-gray-500 ml-1">Meet</span>
                </div>
              </div>

              {solution.totalResponses === 0 && (
                <div className="text-xs text-gray-500 mt-2 text-center italic">
                  No data
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="bg-white rounded-xl p-6 shadow-sm">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-lg font-semibold">Desktop Feedback</h2>
          <div className="flex items-center space-x-6">
            <div className="text-sm text-gray-500">
              Total Responses: {totalDesktopResponses}
            </div>
            <div className="flex items-center space-x-4">
              <div className="flex items-center space-x-2 bg-green-50 px-3 py-1.5 rounded-lg">
                <CheckCircle className="w-4 h-4 text-green-500" />
                <span className="text-sm font-medium text-green-700">
                  {Math.round((totalDesktopChallenges / totalDesktopResponses) * 100)}% Pain
                </span>
              </div>
              <div className="flex items-center space-x-2 bg-blue-50 px-3 py-1.5 rounded-lg">
                <DollarSign className="w-4 h-4 text-blue-500" />
                <span className="text-sm font-medium text-blue-700">
                  {Math.round((totalDesktopPayment / totalDesktopResponses) * 100)}% Pay
                </span>
              </div>
              <button
                onClick={() => setShowDesktopModal(true)}
                className="flex items-center space-x-2 bg-purple-50 px-3 py-1.5 rounded-lg hover:bg-purple-100 transition-colors"
              >
                <MessageCircle className="w-4 h-4 text-purple-500" />
                <span className="text-sm font-medium text-purple-700">
                  {totalDesktopFollowUps} Meet • {completedFollowUps} Done
                </span>
              </button>
            </div>
          </div>
        </div>
        
        <div className="grid grid-cols-7 gap-3">
          {desktopMetrics.map((integration) => (
            <div 
              key={`${integration.id}-${String(integration.name)}}`}
              className={`${
                integration.totalResponses === 0 ? 'bg-gray-50' : 'bg-purple-50'
              } rounded-lg p-2.5`}
            >
              <div className="flex flex-col items-center">
                <div className="h-12 flex items-center text-center">
                  <h3 className="font-medium text-gray-900 text-sm leading-tight">
                    {integration.name}
                  </h3>
                </div>
                <div className={`flex items-center px-2 py-1 rounded-full ${
                  integration.totalResponses === 0 ? 'bg-gray-100' : 'bg-white'
                }`}>
                  <Star className={`w-4 h-4 mr-1 ${
                    integration.totalResponses === 0 ? 'text-gray-400' : 'text-yellow-400 fill-current'
                  }`} />
                  <span className="text-sm font-semibold">
                    {integration.averageRating.toFixed(1)}
                  </span>
                </div>
              </div>

              {integration.totalResponses === 0 && (
                <div className="text-xs text-gray-500 mt-2 text-center italic">
                  No data
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <FollowUpModal
        isOpen={showSolutionsModal}
        onClose={() => setShowSolutionsModal(false)}
        requests={solutionFollowUps}
        title="Solutions Follow-up Requests"
        onFollowUpChange={async (id, followed) => {
          const updatedRequests = solutionFollowUps.map(req =>
            req.id === id ? { ...req, followedUp: followed } : req
          );
          setSolutionFollowUps(updatedRequests);
        }}
        completedCount={solutionFollowUps.filter(r => r.followedUp).length}
      />

      <FollowUpModal
        isOpen={showDesktopModal}
        onClose={() => setShowDesktopModal(false)}
        requests={desktopFollowUps}
        title="Desktop Follow-up Requests"
        onFollowUpChange={handleFollowUpChange}
        completedCount={completedFollowUps}
      />
    </div>
  );
}